import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import LayoutCourse from "../../../../layouts/course"
import AudioPlayer from "../../../../components/audio-player"
import Box from "../../../../components/box"
import Paragraph from "../../../../components/paragraph"
import CourseFooterNext from "../../../../components/course-footer-next"
import FloatContainer from "../../../../components/float-container"
import Heading from "../../../../components/heading"
import Image from "../../../../components/image"
import Seo from "../../../../components/seo"
import Stack from "../../../../components/stack"
import {
  getCourseNavigation,
  getCourseProgress,
} from "../../../../store/courses"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
}

const Page = () => {
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)

  const data = useStaticQuery(graphql`
    query WerwarturmQuery {
      turm: file(relativePath: { eq: "startseite/turm-aussenansicht.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 600, height: 600, layout: CONSTRAINED)
        }
      }
    }
  `)

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/wie-sah-hoelderlin-aus" />
      }
      navigation={navigation}
      progress={progress}
    >
      <Seo title="Wer war Friedrich Hölderlin?" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="span" color="whisper">
              Kapitel 1
            </Heading>
            <Heading as="h2" level={2}>
              Wer war Friedrich Hölderlin?
            </Heading>
          </Stack>
          <AudioPlayer src={`kurse/hoelderlins-reisen/einleitung`} />
          <Paragraph dropcap={true}>
            Friedrich Hölderlin war ein Dichter. Er lebte vor mehr als 200
            Jahren. Hölderlin wurde 1770 in Lauffen am Neckar geboren. Als er 4
            Jahre alt war, zog die Familie weiter nach Nürtingen. Seine Mutter
            wollte, dass er Pfarrer wird – genau wie sein Großvater. Deshalb
            ging Hölderlin auf die Klosterschulen in Denkendorf und Maulbronn.{" "}
          </Paragraph>
          <Box>
            <FloatContainer width="40%" align="right">
              <Image
                image={data.turm.childImageSharp.gatsbyImageData}
                alt="Aussenansicht Hölderlinturm und Neckarfront"
                caption="Der Hölderlinturm in Tübingen"
                attribution="David Franck"
              />
            </FloatContainer>
            <Paragraph mb={[6, 12]}>
              Weil Hölderlin aber viel lieber Gedichte schreiben wollte, wurde
              er nach dem Studium Hauslehrer und unterrichtete die Kinder
              reicher Familien. Er arbeitete auch in der Schweiz und in
              Frankreich. Doch nirgends blieb er länger als 2-3 Jahre.
            </Paragraph>
            <Paragraph>
              Zuletzt kam Hölderlin dann nach Tübingen, wo er die zweite Hälfte
              seines Lebens in einem Turm verbrachte. Diesen Turm nennt man
              heute ›Hölderlinturm‹. 36 Jahre lebte er dort und schrieb
              Gedichte, die heute ›Turmgedichte‹ heißen.
            </Paragraph>
          </Box>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
